/* You can add global styles to this file, and also import other style files */

@import "../../../node_modules/angular-calendar/css/angular-calendar.css";
@import "../../../node_modules/@syncfusion/ej2/material.css";
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-angular-layouts/styles/material.css";
@font-face {
    font-family: 'Bebas Neue';
    src: url('https://upkeeplabs.blob.core.windows.net/doc-public/cdn/elevate/fonts/BebasNeue-Regular.WOFF');
}

* {
    --mdc-snackbar-container-color: rgb(100, 100, 100);
    --card-box-shadow: 1px 1px 24px 1px rgba(0, 0, 0, 0.1019607843);
}


html {
    height: 100%;
}

body {
    height: calc(100% - 20px);
    font-family: "Open Sans";
    padding: 0;
    margin: 0;
    overflow: hidden !important;
    background-color: #F7F8FA;
}

input {
    font-family: verdana;
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, .3);
}

.white-space-pre-line {
    white-space: pre-line;
}


.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 20px;
}

button[mat-icon-button] mat-icon, a[mat-icon-button] mat-icon {
    vertical-align: super;
}

.tool-bar {
    margin-top: 8px;
    margin-bottom: 10px;
}

.menu-panel-class {
    border-radius: 0 4px 4px 4px !important;
    margin-top: -2px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%) !important;
}

.menu-panel-bg-class {
    top: 95px;
}

.pad-tab  .mat-mdc-tab-body-content {
    padding: 15px;
}

.pad-tab mat-card {
    box-shadow: var(--card-box-shadow);
}


.tool-bar>div {
    background-color: #fff;
    display: inline-block;
    padding: 0 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 7%);
}

.tool-bar>div>button,
.tool-bar>div>a {
    color: rgba(0, 0, 0, .5);
    border-right: 1px dotted rgba(0, 0, 0, .1);
    border-radius: 0;
}

.clear .joint-paper.joint-theme-default {
    background-color: transparent !important;
}

.clear .joint-port-label {
    color: #fff !important;
    fill: #eee !important;
}

.card {
    box-shadow: 1px 1px 24px 1px rgba(0, 0, 0, 0.1019607843) !important;
}

.tool-bar>div>button:last-child,
.tool-bar>div>a:last-child {
    border-right: none;
    border-radius: 0 10px 10px 0;
    margin-right: -10px;
}

.tool-bar>div>button:first-child,
.tool-bar>div>a:first-child {
    border-radius: 10px 0 0 10px;
    margin-left: -10px;
}

.fancy-container {
    padding: 30px;
    background-color: #fff;
    min-height: calc(100vh - 60px);
    box-shadow: 0px 0px 162px -19px rgb(0 0 0 / 20%);
}

.mat-sidenav-content {
    background-color: rgb(250, 250, 250) !important;
}

mat-form-field[appearance=outline] .mat-form-field-infix {
    padding: 5px 0 5px 0;
}

mat-sidenav-container {
    height: calc(100% - 40px);
}

.mentioned-href {
    color: #337ab7 !important;
}

.mentioned-href:hover {
    color: #23527c !important;
}

mat-tab-group.no-padding div.mat-tab-body-content {
    padding: 0 !important;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    background-color: rgba(255, 255, 255, .06);
    opacity: .5;
}

.sort-list.cdk-drop-list-dragging .sort-list-item:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-handle {
    cursor: ns-resize;
}

.e-panel-content {
    overflow: hidden;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    background-color: rgb(240, 240, 240);
    border-radius: 25px;
    margin-right: 10px;
    border: 1px solid rgb(220, 220, 220);
    font-weight: 500;
}

.full-width-dialog {
    width: calc(100% - 180px) !important;
    height: calc(100% - 180px) !important;
}

.mat-dialog-content {
    max-height: 100vh !important;
}

.full-width-dialog .mat-dialog-content {
    max-height: calc(100% - 52px) !important;
}

h1 {
    font-family: "Open Sans";
    font-size: 24px;
    font-variant: normal;
    color: #1C053A;
    margin: 0;
    padding: 0;
    font-weight: 100;
}

input[type=number] {
    text-align: right;
}

h2 {
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 100;
    color: #1C053A;
    margin: 0;
    padding: 0;
}

h3 {
    font-family: "Open Sans";
    font-size: 16px;
    font-variant: normal;
    overflow-y: hidden;
    font-weight: 100;
}

.container {
    width: 100%;
    max-width: 1050px;
    margin-right: auto;
    margin-left: auto;
}

.container.shadow {
    padding: 30px;
    background-color: #fff;
    min-height: calc(100vh - 60px);
    box-shadow: 0px 0px 162px -19px rgba(0, 0, 0, 0.2);
}

.wide-container {
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}

mat-card {
    margin-bottom: 3px;
    border: 1px solid rgba(0, 0, 0, .15);
}

mat-card::after {
    content: "";
    position: absolute;
    bottom: -3px;
    right: -13px;
    border-width: 20px 20px 0;
    border-style: solid;
    border-color: #798ca7 transparent;
    display: block;
    width: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: .3;
}

.mat-tab-body-content>mat-card {
    margin: 2px;
}

div[flex],
div[aflex],
mat-radio-group[flex], .flex-container,
div.flex {
    display: flex;
}


div[flex]>*,
div[aflex]>*,
mat-radio-group[flex]>*,
div.flex>* {
    flex: auto;
}

div[flex]>*[nogrow],
div[aflex]>*[nogrow],
mat-radio-grou[flex]>*[nogrow],
div.flex>*[nogrow] {
    flex: none !important;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

mat-radio-button {
    margin-right: 10px;
}

[nogrow],
.no-grow {
    flex: none !important;
}

div[flex]>*:not(:first-child),
div[aflex]>*:not(:first-child),
div.flex>*:not(:first-child) {
    margin-left: 20px;
}

mat-input-container.block {
    display: block
}

a[mat-list-item],
a.mat-mdc-list-item {
    text-decoration: none;
    transition: border-left-color .5s ease-in-out;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 5px;
    cursor: pointer;
}

a[mat-menu-item]:focus {
    outline: none;
}

a[mat-list-item]:hover {
    border-left-color: #417058;
}

[block] {
    width: 100%;
    display: block;
}

a[block] {
    display: block;
    text-align: left;
}

.padding,
[padding] {
    padding: 10px;
}

.padding20,
[padding20] {
    padding: 20px;
}

.padding30,
[padding30] {
    padding: 30px;
}

*[nomargin] {
    margin: 0 !important;
}

.nobottompadding {
    padding-bottom: 0 !important;
}

*[clear],
*[clearfix],
.clearfix,
.clear-fix {
    clear: both;
}

.label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(0, 0, 0, .65) !important;
    display: block;
    text-align: left;
}

.data-label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: #3e707a;
}

mat-tab-body {
    padding-top: 20px;
}

.mat-card-header-text {
    margin: 0 !important;
}

.home-button {
    max-width: 140px;
    overflow-x: hidden;
    white-space: normal !important;
    line-height: 17px !important;
    vertical-align: top !important;
}

.home-button .mat-button-wrapper {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

h1.big-text {
    color: rgb(200, 200, 200);
    font-weight: 100;
    font-size: 60px;
}

h1.document-title {
    font-size: 40px;
    color: rgb(200, 200, 200);
}

*[pull-right] {
    float: right;
}

.mat-card-header-text {
    width: 100%;
    text-transform: lowercase;
}

mat-card-title>button[mat-button] {
    min-width: 0;
    margin-right: -20px;
}

mat-list[dense] .mat-list-item {
    height: 25px !important;
}

*[padding-top] {
    padding-top: 20px;
}

.no-margin p {
    margin: 0 !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.mat-menu-panel.user-notifications {
    max-width: 600px;
}

.fadeOutDown {
    animation-name: fadeOutDown;
    animation-duration: .5s;
}

.white-spinner .mat-progress-spinner circle,
.white-spinner .mat-spinner circle {
    stroke: #fff;
}

.toggle-button::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: #89AAE6;
    visibility: hidden;
    transform: scaleX(0) scaleY(0);
    transition: all .3s ease-in-out 0s;
    height: 100%;
    border-radius: 5px;
    top: 0;
    z-index: -1;
}

.toggle-button.offset::before {
    top: 0;
    left: 0;
}

.snackbar-standard {
    white-space: pre-wrap;
}


.toggle-button.selected {
    color: #fff !important;
    z-index: 1;
}

.toggle-button.selected::before {
    transform: scaleX(1) scaleY(1);
    visibility: visible;
}

@keyframes slideInRight {
    0% {
        transform: translateX(20px);
    }

    30% {
        transform: translateX(1px);
    }

    100% {
        transform: translateX(0px);
    }
}
@keyframes slideInLeft {
    0% {
        transform: translateX(-20px);

        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(25px) scale(0);
        opacity: 0;
    }

    100% {
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

@keyframes slideInDown2 {
    0% {
        transform: translateY(-25%) scale(.5);
        opacity: 0;
    }

    60% {
        transform: translateY(-1%) scale(.75);
        opacity: .6;
    }

    90% {
        transform: translateY(-1%) scale(1.05)
    }

    95% {
        transform: translateY(-1%) scale(.95)
    }

    100% {
        transform: translateY(0%) scale(1);
        opacity: 1;
    }
}

.slide-in-right {
    animation: slideInRight .7s ease-out;
}

.slide-in-left {
    animation: slideInLeft .5s ease;
    transform-origin: 0% 0%;
}

.slide-in-up {
    transform-origin: 0 0;
    animation: slideInUp .25s ease-out;
}

.slide-in-down {
    animation: slideInDown2 .7s ease-out;
}

table {
    width: 100%;
    margin-right: 20px;
}

.total-box {
    background-image: url(https://upkeeplabs.blob.core.windows.net/doc-public/cdn/images/three-triangles.png);
    background-repeat: no-repeat;
    margin-top: 20px;
    min-height: 80px;
    padding-left: 55px;
    text-align: right;
    border-bottom: 5px transparent;
    transition: border 1s ease-out;
    padding-bottom: 10px;
}

.google-map-label {
    position: absolute;
    background: rgba(0, 0, 0, .4);
    color: #fff;
    padding: 2px;
    border-radius: 2px;
}

.total-box:first-child {
    padding-top: 20px;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fade-in-up {
    animation: fadeInUp .5s ease-out;
}

.fade-in-up-small {
    animation: fadeInUpSmall .5s ease-out;
}

table thead {
    border-bottom: 3px solid #673ab7;
    padding: 10px 5px;
    color: rgba(0, 0, 0, .5);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

table thead tr th {
    padding: 6px 8px;
}

table.table tfoot {
    border-top: 2px solid #673ab7;
    padding: 12px 20px;
    color: rgba(0, 0, 0, .5);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

.small-image-container img {
    max-width: 100%;
}

.url-link a {
    margin-left: 10px;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 12px 20px;
}

table tbody tr {
    border-bottom: 1px solid #0d47a1;
    color: rgba(0, 0, 0, .5);
}

table tbody tr td {
    padding: 7px 8px;
}

table.mat-calendar-table thead,
table.mat-calendar-table tbody tr {
    border-bottom: none;
}

div.table-header {
    display: flex;
    border-bottom: 3px solid #0d47a1;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.flex-row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.hover {
    cursor: pointer;
    transition: all .5s ease-out;
}

.hover:hover {
    background-color: rgba(0, 0, 0, .05);
}

table.table tbody tr td {
    padding: 12px 20px;
    font-size: 15px;
}

table.table thead tr th {
    padding: 12px 20px;
}

table.table tbody tr:nth-child(odd) {
    background-color: rgb(250, 250, 250);
}

.table-header>div {
    flex: 1;
    padding: 10px 5px;
    color: #3e707a;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

a.table-row {
    display: flex;
    width: 100%;
}
.table-footer {
    display: flex;
    border-top: 3px solid #0d47a1;
}

.table-row {
    border-bottom: 1px solid #0d47a1;
    color: rgba(0, 0, 0, .5);
    display: flex;
}

.table-row>div, .table-footer>div {
    padding: 10px 5px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.table a {
    text-decoration: none;
}

.current-status {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    color: #697283;
}

.current-status:not(:first-child) {
    margin-top: 20px;
}

*[flex1] {
    flex: 1 !important;
}

*[flex2] {
    flex: 2 !important;
}

*[flex3] {
    flex: 3 !important;
}

*[flex4] {
    flex: 4 !important;
}

*[flex15] {
    flex: 1.5 !important;
}

[right] {
    text-align: right;
}

mat-list.condensed-list .mat-list-item {
    height: 55px !important;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, .8);
}


button[mat-button].condensed-button,
a[mat-button].condensed-button {
    padding: 2px;
    line-height: 14px;
    min-width: unset;
}


mat-list.condensed-list mat-icon[mat-list-avatar] {
    width: 20px !important;
}

h1 span.small {
    font-size: 11px;
    text-transform: uppercase;
}

#noteList mat-list-item:not(.header) .mat-list-item:not(.header),
#noteList mat-list-item:not(.header) {
    height: auto !important;
}

#noteList .mat-list-item:not(.header) {
    height: auto !important;
    padding: 0;
}

.mat-form-field-infix {
    width: 100% !important;
}

.collapse-padding .mat-form-field-infix {
    padding: 0 !important;
}

.mat-list .mat-list-item.mat-3-line .mat-list-item-content,
.mat-nav-list .mat-list-item.mat-3-line .mat-list-item-content {
    height: auto !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.info-box {
    background-color: rgba(0, 0, 0, .03);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, .1);
    -webkit-animation: fadeInRight .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    animation: fadeInRight .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    margin-bottom: 10px;
}

@keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.rubberBand {
    animation: rubberBand 1s linear;
}

ng-component {
    height: 100%;
    overflow: auto;
}

mat-input-container {
    display: block !important;
    /* margin-bottom: 30px; */
}

button[large],
a[large],
button.large,
a.large {
    font-size: 22px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: unset !important;
}

.mat-tab-body-content {
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.pull-right {
    float: right;
}

.dashboard-chart-container {
    background-color: rgb(40, 74, 92);
    color: #fff;
    box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.75) !important;
}

.dashboard-chart-container::after {
    border: none;
}

.dashboard-chart-container mat-card-title {
    color: #fff;
    font-family: 'Titillium Web';
    font-weight: 100;
    font-size: 36px !important;
    text-align: center;
    margin-bottom: 30px;
}

/* #searchContainer label.mat-input-placeholder, #searchContainer label.mat-input-placeholder {
    color: rgba(255, 255, 255, .5);
} */

/* #searchContainer input {
    color: rgba(255, 255, 255, .5);
} */
.mat-form-field-label-wrapper {
    overflow: visible !important;
}

label.mat-form-field-label {
    text-transform: uppercase;
    color: #3e707a;
    overflow: visible;
}

/* #searchContainer label.mat-form-field-label {
    margin-left: 30px;
} */

/* 
#searchContainer .mat-form-field-outline-start,  #searchContainer .mat-form-field-outline-end  {
    border-color: rgba(255,255,255.3);
} */

/* #searchContainer .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white;
  } */

/* .non-header #searchContainer input {
    color: rgba(0, 0, 0, .7);
}

.non-header #searchContainer {
    margin-left: 0 !important;
}

.non-header #searchContainer label.mat-input-placeholder, .non-header #searchContainer label.mat-input-placeholder {
    color: #637283;
} */

/* don't know why this had to be added to the global css, doesn't work in the local 'payment-selection.css' */

#existingCards label.mat-radio-label {
    width: 100% !important;
}

#existingCards div.mat-radio-label-content {
    width: 100%;
}

#individualStats .mat-tab-label-container,
#individualStats mat-tab-header {
    display: none !important;
}

.no-padding mat-tab-body,
.no-padding .mat-tab-body-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.no-border tr {
    border-bottom: none !important;
}

mat-form-field.no-padding .mat-form-field-wrapper {
    padding: 0 !important;
}

.condensed-labels .mdc-tab {
    min-width: 90px;
}

mat-form-field.condensed-form-field {
    margin-bottom: -20px;
}

mat-form-field.condensed-form-field .mat-mdc-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
}

/*This may be a cause of future issues*/

ng-component {
    overflow-x: hidden !important;
}

mat-dialog-container {
    animation: slideInDown .5s ease-out;
}

.mat-dialog-container {
    height: auto !important;
    margin-top: 65px;
    margin-bottom: 5px;
}

rating i.glyphicon-star,
rating i.glyphicon-star-empty {
    font-size: 44px;
    color: #0d47a1;
}

a:focus,
a:hover {
    text-decoration: unset;
}

/* #appHeader a:focus,
#appHeader a:hover {
  color: #fff;
} */

rating span {
    outline: none;
    cursor: pointer;
}

button[mat-button] {
    color: #0d47a1;
    font-family: "Open Sans";
}

.home-button i {
    text-align: center;
    display: block;
    font-size: 44px;
    margin-top: 15px;
    color: #0d47a1;
}

.home-button.mat-raised-button[color=primary] i,
.home-button.mat-raised-button[ng-reflect-color=primary] i {
    color: #fff;
}

.home-button:not(i) {
    text-transform: uppercase;
    font-size: 12px;
    color: #0d47a1;
}

.center {
    text-align: center;
}

.avatar {
    height: 30px !important;
    border-radius: 100%;
    margin-right: 10px;
}

.conversation-text {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    word-break: break-all;
    word-wrap: break-word;
}

.conversation-bubble {
    position: relative;
    padding: 15px;
    margin: 1em 0 3em;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.incoming.conversation-bubble {
    color: #000;
}

.incoming {
    background: #f1f3f4;
}

.incoming:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #f1f3f4;
    border-left: 0;
    border-top: 0;
    margin-top: -10px;
    margin-left: -20px;
}

.outgoing {
    background: #d2e3fc;
}

.outgoing:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: #d2e3fc;
    border-right: 0;
    border-top: 0;
    margin-top: -10px;
    margin-right: -20px;
}

a[mat-button] {
    font-family: "Open Sans";
}

#appHeader button[mat-button] {
    color: #fff;
}

.clearfix {
    clear: both;
}

.table {
    margin-top: 30px;
}

.right {
    text-align: right;
}

.show-gt-sm {
    display: none;
}

.show-gt-xs {
    display: none;
}

.nomargin,
[nomargin] {
    margin: 0;
}

.leftmargin10,
[leftmargin10] {
    margin-left: 10px !important;
}

.leftmargin20,
[leftmargin20] {
    margin-left: 20px !important;
}

.rightmargin10,
[rightmargin10] {
    margin-right: 10px !important;
}

.info {
    background-color: rgb(245, 245, 245);
    padding: 10px;
    border: 1px solid rgb(200, 200, 200);
}

.info-2 {

    font-family: 'Saruban Light', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 1.4em;
    padding-bottom: 10px;

}

.rightmargin20,
[rightmargin20] {
    margin-right: 20px !important;
}

.topmargin80,
[topmargin80] {
    margin-top: 80px !important;
}

.topmargin70,
[topmargin70] {
    margin-top: 70px !important;
}

.topmargin60,
[topmargin60] {
    margin-top: 60px !important;
}

.topmargin50,
[topmargin50] {
    margin-top: 50px !important;
}

.topmargin40,
[topmargin40] {
    margin-top: 40px !important;
}

.topmargin5,
[topmargin5] {
    margin-top: 5px !important;
}

.topmargin20,
[topmargin20] {
    margin-top: 20px !important;
}

[topmargin20block] {
    margin-top: 20px !important;
    display: block;
}

[topmargin40block] {
    margin-top: 40px !important;
    display: block;
}

.hover-row {
    transition: background-color .5s ease-out;
    cursor: pointer;
}

.hover-row:hover {
    background-color: rgba(0, 0, 0, .08);
}

.topmargin10,
[topmargin10] {
    margin-top: 10px !important;
}

.topmargin30,
[topmargin30] {
    margin-top: 30px !important;
}

.bottommargin10,
[bottommargin10] {
    margin-bottom: 10px !important;
}

.bottommargin20,
[bottommargin20] {
    margin-bottom: 20px !important;
}

.bottommargin30,
[bottommargin30] {
    margin-bottom: 30px !important;
}

.bottommargin40,
[bottommargin40] {
    margin-bottom: 40px !important;
}

.side-column {
    width: 400px;
    margin-bottom: 20px;
}

.hide-gt-sm {
    display: none;
}

@media (max-width: 600px) {}

@media (max-width: 960px) {

    [flex],
    div[flex],
    .flex,
    div.flex {
        display: block;
    }

    div[flex]>*:not(:first-child),
    .flex>*:not(:first-child),
    div.flex>*:not(:first-child) {
        margin-left: 0;
    }

    .side-column {
        width: 100%;
    }

    .hide-gt-sm {
        display: initial;
    }
}

@media (min-width: 601px) {
    .show-gt-xs {
        display: initial;
    }
}

@media (min-width: 961px) {
    .show-gt-sm {
        display: initial;
    }
}

.number-cell {
    text-align: right;
    width: 150px;
    flex: none;
    background-color: rgba(0, 0, 0, .05);
}

.printonly,
[printonly] {
    display: none;
}

ul.dropdown-menu>li {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

ul.dropdown-menu>li:last-child {
    border-bottom: none;
}

.page-icon {
    font-size: 84px;
    color: #798ca7;
}

.data-label-appendage {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
}

mat-spinner.thirty,
button mat-spinner,
mat-spinner[thirty] {
    display: inline-block;
    height: 30px !important;
    width: 30px !important;
}

mat-spinner.thirty svg,
button mat-spinner svg,
mat-spinner[thirty] svg {
    height: 30px !important;
    width: 30px !important
}

mat-spinner.twenty,
mat-spinner[twenty] {
    display: inline-block;
    height: 20px !important;
    width: 20px !important;
}

mat-spinner.twenty svg,
mat-spinner[twenty] svg {
    height: 20px !important;
    width: 20px !important;
}

mat-spinner.onetwentyeight,
mat-spinner[onetwentyeight] {
    display: inline-block;
    height: 128px;
    width: 128px;
}

mat-toolbar .mat-checkbox-layout {
    font-weight: normal;
    font-size: 14px;
    color: #0d47a1;
    text-transform: uppercase;
}

mat-checkbox.wrap span.mat-checkbox-label {
    white-space: normal;
}

mat-checkbox label {
    margin-bottom: 0;
    font-weight: unset;
    margin-top: 5px;
}

button[small],
a[small] {
    min-width: 33px;
    padding: 0 5px;
}

.hide-tabs mat-tab-header {
    display: none;
}

.hide-tabs mat-expansion-panel-header {
    display: none;
}

mat-chip-list mat-chip.mat-standard-chip {
    margin-top: -4px !important;
    margin-bottom: 0px !important;
    padding: 1px 12px !important;
    min-height: 27px;
}

.hide-tabs mat-tab-body {
    padding-top: 0;
}

.show-tabs mat-tab-header {
    display: initial !important;
}

[inline-block-children]>div {
    display: inline-block;
    margin-bottom: 20px;
    vertical-align: top;
}

[inline-block-children]>div:not(:first-child) {
    margin-left: 40px;
}

.show-tabs mat-tab-body {
    padding-top: 20px !important;
}

.big-heading {
    font-size: 57px !important;
    color: rgba(0, 0, 0, .2);
    font-family: "Open Sans";
    text-transform: lowercase;
    line-height: 36px;
}

.big-heading-little {
    font-size: 30px;
    line-height: 25px;
    display: inline-block;
    margin-left: 15px;
}

button[block] {
    display: block;
    width: 100%;
}

[max800],
.max800 {
    max-width: 800px;
}

[max900],
.max900 {
    max-width: 900px;
}

[max1000],
.max1000 {
    max-width: 1000px;
}

.list-delete-button {
    width: 40px;
    height: 40px;
    padding: 0 !important;
    border-radius: 100% !important;
    min-width: 40px !important;
    margin-top: -30px !important;
    margin-right: -20px !important;
}

.mat-input-element {
    background-color: rgba(0, 0, 255, .03) !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

mat-form-field[appearance=outline] .mat-input-element {
    background-color: transparent !important;
}

mat-form-field[appearance=outline] .mat-form-field-outline {
    /* background-color: rgba(0, 0, 0, .02); */
    border-radius: 7px;
}

.mat-form-field-appearance-outline input.mat-input-element {
    background-color: transparent !important;
    padding: 0 !important;
    border-radius: 2px !important;
}

.left-button {
    text-align: left !important;
    display: block !important;
    width: 100% !important;
    margin-right: 15px !important;
}

.active {
    background-color: rgba(0, 0, 0, .1) !important;
}

.example-sidenav-content {
    min-height: calc(100% - 30px);
}

ng2-page-transition {
    min-height: 100%;
}

ng2-page-transition>div {
    min-height: 100%;
}

ng2-page-transition>div>ng-component {
    min-height: 100%;
}

.really-big {
    font-size: 84px;
    text-align: center;
    display: block;
}

.kinda-big {
    font-size: 44px;
    text-align: center;
    display: block;
}

.big-data {
    font-size: 56px;
    color: rgba(0, 0, 0, .4);
}

/*this may break some stuff, need to keep an eye out for problems*/

.mat-tab-body.mat-tab-body-active {
    overflow-y: hidden !important;
}

.table-row mat-select,
.table-row mat-input-container {
    margin-bottom: 0;
}

.table-row .mat-input-underline,
.table-row .mat-select-underline {
    display: none;
}

.mat-form-field-appearance-outline .mat-form-field-infix,
.mat-form-field-appearance-outline .mat-form-field-appearance-outline {
    padding: .5em 0;
}

/* .mat-select {
    line-height: 1.5;
} */

.table.dense div.table-row>div {
    padding: 0px 5px;
}

.plain-box {
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    margin-top: 5px;
    background-color: transparent;
}

.mat-sidenav-content {
    overflow-x: hidden !important;
}

.dotted-vert-separator {
    border-right: 1px dotted rgba(0, 0, 0, .3);
}

@media(max-width: 768px) {
    [hidexs] {
        display: none;
    }
}

@media(min-width: 769px) {
    [showxs] {
        display: none;
    }
}

h1.page-title {
    font-size: 57px !important;
    color: rgba(0, 0, 0, .2);
    font-family: "Open Sans";
    text-transform: lowercase;
    line-height: 36px;
    text-align: right;
    display: inline-block;
}

/* h1 span.big {
  font-size: 54px;
  color: #637283;
} */

h1 span.little {
    font-size: 30px;
    line-height: 25px;
    display: inline-block;
    margin-left: 15px;
}

h1 div.big {
    font-size: 44px;
    color: rgba(0, 0, 0, .8);
}

h1 div.little {
    font-size: 20px;
    color: rgba(0, 0, 0, .4);
}

.left-pane {
    width: 350px;
    flex: none !important;
}

.left-pane-fixed {
    width: 350px;
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
}

#openTasksArc svg {
    transform: scale(.6);
    margin-top: -30px;
}

.mat-tab-body-content,
.mat-mdc-tab-body-content {
    overflow: hidden !important;
    /* padding-bottom: 0; */
}

mat-tab-group.overflow {
    .mat-tab-body-content,
.mat-mdc-tab-body-content {
    overflow: auto !important;
    /* padding-bottom: 0; */
}
}

mat-card.fancy {
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 24px 1px !important;
    margin-bottom: 30px;
}

.mat-tree {
    background: transparent;
}

.mat-tree-node {
    min-height: 35px !important;
}

mat-list-item.header {
    background-color: #2196f3;
    color: #fff !important;
    height: auto !important;
    display: block;
    text-align: left;
    padding: 0;
}

mat-list-item.header:hover {
    color: #1e1e1e !important;
}

.mat-ink-bar {
    background-color: #0247a1 !important;
}

.mat-mdc-raised-button:not(:disabled) {
    background-color: #e76262;
}

.mat-input-underline .mat-input-ripple {
    background-color: #637283;
}

.mat-focused .mat-input-placeholder {
    color: #798ca7;
}

.mat-progress-spinner path,
.mat-spinner path {
    stroke: #0d47a1;
}

.mat-drawer-inner-container {
    /* background-color: #697e8e; */
    background: linear-gradient(180deg, rgb(74, 77, 93) 0, rgb(134, 137, 151));
    overflow-x: hidden !important;
}

.mat-drawer-inner-container a[mat-button],
.mat-drawer-inner-container h1,
.mat-drawer-inner-container button[mat-button] {
    color: #fff;
}

.sub-heading,
mat-card-title {
    font-size: 32px !important;
    color: rgba(0, 0, 0, .4);
    font-family: "Open Sans";
    text-transform: lowercase;
    font-weight: 100;
    /* white-space: nowrap; */
}

.mat-form-field-label-wrapper {
    top: -1.3em;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    min-height: 42px;
}

.full-card-header .mat-card-header-text,
.full-card-header mat-card-title {
    display: block;
    width: 100%;
}

[mat-button] {
    text-transform: uppercase;
}

[mat-button][disabled] i.material-icons {
    color: rgba(0, 0, 0, .3);
}

mat-list [mat-button] {
    text-transform: none;
    padding: 0;
}

mat-list>[mat-button] {
    text-align: left;
    width: 100%;
    display: block;
}

.mat-datepicker-content-touch .mat-calendar {
    width: 64vmin;
    height: 80vmin;
    min-width: 250px;
    min-height: 312px;
    max-width: 350px !important;
    max-height: 420px !important;
}

app-date-picker {
    width: 100%;
    display: block;
}

[paddingtop10],
.paddingtop10 {
    padding-top: 10px !important;
}

[paddingtop20],
.paddingtop20 {
    padding-top: 20px !important;
}

[paddingtop30],
paddingtop30 {
    padding-top: 30px !important;
}

[paddingtop40],
paddingtop40 {
    padding-top: 40px !important;
}

[margintop10],
.margintop10 {
    margin-top: 10px !important;
}

[margintop20],
.pmargintop20 {
    margin-top: 20px !important;
}

[margintop30],
margintop30 {
    margin-top: 30px !important;
}

[margintop40],
margintop40 {
    margin-top: 40px !important;
}

mat-dialog-actions {
    width: 100%;
    display: block !important;
}

mat-dialog-content {
    overflow: hidden !important;
    padding-bottom: 20px !important;
}

.mat-form-field {
    width: 100% !important;
}

.table-row mat-select {
    margin-top: 0;
    padding-top: 0;
}

/* mat-card {
    box-shadow: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid #798ca7;
} */


.warning-toast {
    background: linear-gradient(135deg, #f5bb64 0%, #fea858 100%);
}

.snackbar-standard.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: orange !important;
}

.failed-toast {
    background-color: #b2761c !important;
}

.failed-toast.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: transparent !important;
}

.success-toast {
    background-color: rgb(247, 247, 247) !important;
}

.success-toast {
    background-color: rgb(240, 240, 240);
}

.success-toast.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: transparent !important;
}

.failed-toast button {
    color: #fff !important;
}

.three-hundred.mat-autocomplete-panel {
    width: 300px;
}

@keyframes throb {
    from {
        box-shadow: 0 0 0 0px #347deb;
    }

    to {
        box-shadow: 0 0 31px 0px #347deb;
    }
}

@keyframes throb-after {
    from {
        transform: scale(1);
        opacity: .5;
    }

    to {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes throb-after-2 {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(2);
        opacity: 0;
    }
}

.throbber {
    background-color: #347deb;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 0 31px 33px #347deb;
    animation: throb 3s linear;
    animation-iteration-count: infinite;
    position: relative;
}

.throbber::after {
    content: ' ';
    border: 1px solid #347deb;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    transform-origin: center;
    animation: throb-after 3s linear;
    animation-iteration-count: infinite;
}

.throbber::before {
    content: ' ';
    border: 1px solid #347deb;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    transform-origin: center;
    animation: throb-after-2 3s linear;
    animation-iteration-count: infinite;
    position: absolute;
    left: calc(50% - 6px);
    top: calc(50% - 6px);
}

mat-spinner.small {
    display: inline-block;
    height: 20px !important;
    width: 20px !important;
}

mat-spinner.small svg {
    height: 20px !important;
    width: 20px !important;
}

.table-row .mat-input-wrapper {
    padding: 0 !important;
}

.table-row .mat-input-infix {
    padding: 0 !important;
    margin: 0 !important;
    border-top: none !important;
}

.table-row input,
.table-row textarea {
    padding: 0 !important;
}

.circular-button {
    border-radius: 100% !important;
    height: 85px;
    width: 85px;
    border: 2px solid rgba(0, 0, 0, .1) !important;
    margin-right: 20px !important;
}

.circular-button .mat-button-ripple {
    border-radius: 100%;
}

.circular-button .mat-button-wrapper {
    line-height: 16px;
}

.circular-date-picker .datepicker__input {
    border: 2px solid rgba(0, 0, 0, .1) !important;
    border-radius: 100% !important;
    height: 85px;
    width: 85px;
    margin-bottom: -10px;
    font-size: 13px !important;
}

[nopadding] .mat-tab-body-content {
    padding: 0 !important;
}

.white-label .mat-form-field-label {
    color: #fff !important;
}

.mat-simple-snackbar-action button {
    color: rgba(0, 255, 255, .5);
}

#amazon-ccp-phone-call-div iframe {
    border: none !important;
}

#extended-report-criteria-inner mat-form-field,
#settings-panel mat-form-field {
    margin-top: -20px;
}

.multi-line .mat-select-panel mat-option.mat-option,
.multi-line mat-option.mat-option {
    height: unset;
    line-height: unset;
    padding: 6px 16px;
}

.multi-line .mat-option-text.mat-option-text {
    white-space: normal;
}

#left-pane,
.left-pane {
    width: 350px;
    border-right: 1px dotted;
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
}

#left-pane .header,
.left-pane .header {
    background-color: #b4bebe;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(180, 190, 190, 1)), to(rgba(180, 180, 180, 1)));
    background: linear-gradient(to bottom, rgba(180, 190, 190, 1) 0, rgba(180, 180, 180, 1) 100%);
    color: #fff;
    padding: 7px 15px;
}

#left-pane button:not([color=primary]),
.left-pane button:not([color=primary]),
#left-pane a,
.left-pane a {
    display: block;
    width: 100%;
    text-align: left;
    line-height: 25px;
    /* margin-bottom: 10px; */
    text-transform: none;
    padding: 10px;
}

#left-pane button.selected,
.left-pane button.selected,
#left-pane a.selected,
.left-pane a.selected {
    background-color: rgba(0, 0, 0, .1);
}

#left-pane button.add-new,
.left-pane button.add-new {
    text-align: center;
}


.left-pane mat-toolbar button:not([color=primary]) {
    display: inline-block;
    width: unset;
    text-align: center;
    line-height: 22px;
    padding: calc(calc(var(--mdc-icon-button-state-layer-size, 48px) - var(--mdc-icon-button-icon-size, 24px)) / 2);
}

/* .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    pointer-events: none;
} */
/* The popup bubble styling. */
.popup-bubble {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the bubble. */
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    font-family: sans-serif;
    overflow-y: auto;
    max-height: 60px;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid white;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
    cursor: auto;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
}

.joint-paper image {
    transition: filter .2s ease-in-out;
}

.highlighted image {
    filter: drop-shadow(0px 0px 12px rgb(63, 169, 245));
}

path[joint-selector=wrapper]:hover {
    stroke: rgb(63, 169, 245);
    stroke-width: 3px;
}

.joint-port-label {
    font-size: 10px;
    font-weight: bold;
    fill: #666;
}

text[joint-selector=label]:not(.joint-port-label) {
    fill: rgb(63, 169, 245);
    font-size: 11px;
}

button.mdc-button,
a.mdc-button {
    display: inline-block;
    height: unset;
    text-align: center;
    line-height: 36px;
}

mat-form-field.mat-mdc-form-field {
    display: block;
}

/* .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 10px;
} */

.mat-mdc-form-field-infix {
    min-height: auto;
}

button.mat-mdc-button>.mat-icon,
a.mat-mdc-button>.mat-icon {
    font-size: 24px;
    height: 24px;
    width: 24px;
    vertical-align: middle;
}

/* label.mdc-floating-label {
    top: 24px;
} */
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 25px;
}

a.mdc-list-item.mdc-list-item--with-one-line,
button.mdc-list-item.mdc-list-item--with-one-line {
    height: auto;
}

[mat-list-avatar] {
    border-radius: 50%;
}

a.mat-mdc-list-item:not(.mat-mdc-list-item-interactive),
a.mat-mdc-list-option:not(.mat-mdc-list-item-interactive) {
    cursor: pointer;
}

button.mdc-button {
    padding: 0 16px;
}

.mat-mdc-card {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
}

.mdc-card {
    display: block !important;
}

.mat-mdc-menu-panel {
    max-width: 500px !important;
}

[mat-list-avatar] {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

button[mat-button] i.material-icons,
a[mat-button] i.material-icons {
    vertical-align: middle;
}

mat-checkbox div.mdc-checkbox {
    height: 12px;
}

mat-checkbox mdc-label {
    margin-bottom: 0;
}

.success-toast {
    background-color: rgb(247, 247, 247) !important;
}

button mat-icon, a[mat-button] mat-icon {
    vertical-align: middle;
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes info-call-out-animate {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes info-call-out-target-wiggle {
    from {
        transform: rotate(20deg);
    }
    10% {
        transform: rotate(-20deg);

    }
    20% {
        transform: rotate(20deg);

    } 
    30% {
        transform: rotate(-20deg);

    }   
    40% {
        transform: rotate(20deg);

    }
    50% {
        transform: rotate(-20deg);

    }
    60% {
        transform: rotate(20deg);

    }
    70% {
        transform: rotate(-20deg);

    }
    80% {
        transform: rotate(20deg);

    }
    90% {
        transform: rotate(-20deg);

    }
    100% {
        transform: rotate(0deg);
    }
}

.info-call-out-target-view {
    transform-origin: 50% 50% !important;
    animation: info-call-out-target-wiggle 1s ease;
}

.info-call-out {
    position: fixed;
    background-color: #fff;
    padding: 10px;
    border-radius: 0px;
    transform-origin: 0% 0%;
    animation: info-call-out-animate 0.5s ease;
    transition: transform 0.5s ease;
    /* color: #fff; */
    border: 1px solid #eee;
    box-shadow: 6px 6px 11px 0px rgba(0, 0, 0, 0.15);
    z-index: 100000;
    cursor: pointer;

    &.removing {
        transform: scale(0);
    }

    span.call-out-text {
        max-width: 238px;
        display: block;
    }

    h2 {
        margin-bottom: 5px;
        color: #444;
        font-size: 18px;
        line-height: normal;
        font-weight: bold;
    }



    &.bottom {
        transform-origin: 50% 0%;
        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: #eee transparent;
            border-width: 0 13px 13px;
            top: -13px;
            left: calc(50% + 6.5px);
            margin-left: -20px;

        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: #fff transparent;
            border-width: 0 13px 13px;
            top: -13px;
            left: calc(50% + 6.5px);
            margin-left: -20px;
        }
    }

    &.top {
        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: #eee transparent;
            border-width: 13px 13px 0;
            bottom: -13px;
            left: 35px;
            margin-left: -20px;

        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: #fff transparent;
            border-width: 13px 13px 0;
            bottom: -13px;
            left: 35px;
            margin-left: -20px;
        }
    }

    &.left {
        transform-origin: 100% 50%;
        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: transparent #eee;
            border-width: 13px 0 13px 13px;
            top: calc(50% - 2.5px);
            right: -13px;
            margin-top: -13px;
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: transparent #fff;
            border-width: 13px 0 13px 13px;
            top: calc(50% - 2.5px);
            right: -13px;
            margin-top: -13px;
        }
    }

    &.right {
        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: transparent #eee;
            border-width: 13px 13px 13px 0;
            top: 25px;
            left: -13px;
            margin-top: -13px;
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            z-index: 1;
            border-style: solid;
            border-color: transparent #fff;
            border-width: 13px 13px 13px 0;
            top: 25px;
            left: -13px;
            margin-top: -13px;
        }
    }
}

.solid-shadow {
    box-shadow: 12px 12px 0px -3px #ddd;
    border-radius: 30px;
    overflow: hidden;
    background-color: #fff;
}

.solid-shadow.small {
    
    box-shadow: 6px 6px 0px -3px #ddd;
    border-radius: 50px;
    border: 1px solid #eee;
}